import React, { Component } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import ReactPlayer from "react-player";
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image";
import LazyLoad from 'react-lazyload';


class About2 extends Component {


    render() {


        return (
            <>
                <Header/>
                <StaticQuery
                    query={graphql`
                    query {
                        desktop: file(relativePath: { eq: "duynhat/company/DJI_0150-copy.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                        }
                        truck: file(relativePath: { eq: "duynhat/factory/DSC_7915.jpg" }) {
                            childImageSharp {
                                fluid(jpegQuality: 100,maxWidth:1024) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                            }
                        cert: file(relativePath: { eq: "duynhat/iso.jpg" }) {
                            childImageSharp  {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid
                                  }
                            }
                            }
                            brc: file(relativePath: { eq: "duynhat/brc.jpg" }) {
                                childImageSharp  {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid
                                      }
                                }
                                }
                    }
                    `}
                    render={data => {
                    const imageData = data.desktop.childImageSharp.fluid
                        return (
                <div class="page-content bg-white">
                    {/* <div class="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bnr3 + ")" }}> */}
                    
                    <BackgroundImage
                        fluid={imageData}
                        >
                    <div class="dlab-bnr-inr overlay-black-middle">

                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">About</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About Duy Nhat Pack</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </BackgroundImage>
                      
                 
                    
                    <div class="section-full">
                            <div class="row spno about-industry">
                             
                    
                                <div class="col-lg-12 bg-secondry text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title">The DN® Pack<br/><span> Story. </span> </h2>
                                            <p style={{marginBottom:0,textAlign:'center'}}>Duy Nhat trading and manufacturing company (<a href="https://www.dnpack.com.vn" style={{color:"white",textDecorationLine:"underline"}}>Bao Bì Duy Nhật - Duy Nhat Pack - DN Pack</a>) was established in the year 2004. 
                                            The company specializes in printing &amp; converting paper and soft packaging mainly for food industry and consumer goods. Since beginning of 2020, DN Pack has re-located to our manufacturing 
                                            facility to a larger production factory to An Nghiep Industrial Park, further increasing our production capabilities and 
                                            ensuring the highest level of product quality. With DN Pack highly experienced management team, state of the art technology, 
                                            and highly trained staffs, we strive to provide our customer with the highest level of satisfaction. </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="row justify-content-center" style={{ background:  "linear-gradient(360deg, #FFFFFF 70%,#880006 70%, #880006 30%)"}}>
                                    <LazyLoad>
                                        <ReactPlayer url="https://www.youtube.com/watch?v=eclyagw9Ui0"
                                                                    volume = {0.4}
                                                                    controls = {true}
                                                                    // width={500}
                                                                    // height = {400}
                                                                    // style={{
                                                                    // left: '50%', top: '50%',
                                                                    // transform: 'translate(-50%, -50%)'}}
                                                            />
                                    </LazyLoad>
                                
                            </div>
                        </div>

                                <div class="container">
                           
                                    <div class="row justify-items-center">
                                        {/* <div class="col-lg-6 col-md-12 m-b30">
                                                <div class="video-bx">
                                            
                                                </div>
                                            </div> */}
                                            <p></p>
                                            {/* <div class="col-lg-6 col-md-12 m-b30 align-self-center video-infobx"> */}
                                                <div class="content-bx1">
                                                {/* <h2 class="title">Thriving to <br/> deliver<br/><span class="text-primary">since 2004</span></h2> */}
                                                <p style={{textAlign:'center'}}>Since our establishment in the year 2004, Duy Nhat Pack has been specializing in printing &amp; 
                                                converting paper and soft packaging mainly for food industry such as frozen seafood, agriculture products and 
                                                consumer goods. Our paper packaging includes various kinds of boxes, labels, paper bags, etc.For soft packaging, 
                                                we handle various kinds of laminated bags &amp; rolls for auto packing.</p>
                                                <p style={{textAlign:'center'}}> DN Pack is proud to produce packaging products for leading seafood exporters, 
                                                FMCG companies, local and international manufacturers. Many packaging products by Duy Nhat Pack are found in major global
                                                retailers including Coles, Woolworths, Aldi, Costco, and independents, P&amp;C and Food Service.</p>
                                                <p style={{textAlign:'center'}}>We have been implimenting  modern quality assurance programs: ISO 9001:2005 &amp; BRC for food packaging  
                                                into our production  activities  so as to minimize risks &amp; errors to products prior delivering to 
                                                customers. Yet, we keep innovating ourselves to a green packaging industry by collaborating with World-renown plastic 
                                                suppliers to make our packaging eco-friendly, easy to recyle, and downgauging  plastic wastes into the environment.</p>
                                                <p style={{textAlign:'center'}}>With our working slogan:  Speedy – Accuracy – Professional, we have strong commiments to work 
                                                closely with clients and partners on the basis of mutual benefits and cooperative manners and to provide 
                                                customers with safe products, quick and supportive services. Your satisfaction  is our key to success!</p>
                                                    {/* <img src={require('../images/sign.png')} width="200" alt="" /> */}
                                                    <h4 class="m-b0" align="right">Duc Xung Bui</h4>
                                                    <span class="font-14" style={{float:'right',marginBottom:15}}>Company Director</span>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                    <Img fluid={data.cert.childImageSharp.fluid}  alt="" className="img-cover"/>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                    <Img fluid={data.brc.childImageSharp.fluid}  alt="" className="img-cover"/>
                                                </div>
                                    </div>


                                </div>
    
                        <div class="section-full box-about-list">
                            <div class="row spno">
                                <div class="col-lg-6 col-md-12"> 
                                    <LazyLoad>
                                            {/* // <img src={require("../images/duynhat/factory/DSC_7915.jpg")} alt="" class="img-cover"/>  */}
                                        <Img fluid={data.truck.childImageSharp.fluid} alt="" className="img-cover"/>
                                    </LazyLoad>

                                </div>
                                <div class="col-lg-6 col-md-12 bg-primary">
                                    <div class="max-w700 m-auto p-tb50 p-lr20">
                                        <div class="text-white">
                                            <h2>We're committed to our slogan, <br/>providing better products</h2>
                                        </div>
                                        <div class="icon-bx-wraper m-b30 left">
                                            <div class="icon-md"> 
                                                <Link to={"/#"} class="icon-cell text-white">
                                                    <i class="fa fa-calendar"></i>
                                                </Link> 
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Speedy</h4>
                                                <p>
                                                    <ul class="list-check secondry">
                                                    <li>
                                                    Production process must go smoothly with business plan and quick delivery must be ensured with priority placed on quality.
                                                    </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="icon-bx-wraper m-b30 left">
                                            <div class="icon-md"> 
                                                <Link to={"/#"} class="icon-cell text-white">
                                                    <i class="fa fa-check"></i>
                                                </Link> 
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Accuracy</h4>
                                                <p>
                                                <ul class="list-check secondry">
                                                <li>All completed designs must be inspected thoroughly in terms of colors, contents, measurements.</li>

                                                </ul>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="icon-bx-wraper left">
                                            <div class="icon-md"> 
                                                <Link to={"/#"} class="icon-cell text-white">
                                                    <i class="fa fa-briefcase"></i>
                                                </Link> 
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Professional</h4>
                                                <p>
                                                <ul class="list-check secondry">
                                                    <li>
                                                    Highly trained and skilled staffs with streamlined &amp; integrated process from designing, artwork processing,  
                                                    plating, printing and converting.
                                                    </li>
                    
                                                </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                      )
                    }}
                    />
                <Footer/>
            </>
        )
    }
}
export default About2;